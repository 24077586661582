:root:has(> body.light) {
  color-scheme: light;
}
:root:has(> body.dark) {
  color-scheme: dark;
}
body.light {
  /* <body> */
  margin: 0;
  background-color: #FFFFFF;
  transition: background-color 300ms;
  -ms-transition: background-color 300ms;
  -webkit-transition: background-color 300ms;
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq3p6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf) format('truetype');
}
body.light .title-stripe {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 220px;
  margin: 0;
  text-align: center;
}
@media (max-width: 500px) {
  body.light .title-stripe {
    height: 140px;
  }
}
body.light .title-stripe h2,
body.light .title-stripe span {
  padding: 0 45px;
  position: relative;
  z-index: 1;
  background-color: #FFFFFF;
  transition: background-color 300ms;
  -ms-transition: background-color 300ms;
  -webkit-transition: background-color 300ms;
}
@media (max-width: 500px) {
  body.light .title-stripe h2,
  body.light .title-stripe span {
    padding: 0 8px;
  }
}
body.light .title-stripe:before {
  content: "";
  display: block;
  border-top: solid 12px #FFAC26;
  width: 100%;
  position: absolute;
  top: calc(calc((220px / 2) - calc(12px / 2)));
  z-index: 0;
}
@media (max-width: 500px) {
  body.light .title-stripe:before {
    top: calc(calc((140px / 2) - calc(12px / 2)));
  }
}
body.light .cross {
  position: absolute;
  display: inline-block;
  background-color: #4D4D4D;
  transition: background-color 300ms ease-out;
}
@media (max-width: 680px) {
  body.light .cross {
    height: 115px;
    width: 16px;
    left: calc(calc(16px / -2) - 6px / 2);
  }
}
@media (min-width: 681px) {
  body.light .cross {
    height: 150px;
    width: 20px;
    left: calc(calc(20px / -2) - 6px / 2);
  }
}
body.light .cross:after {
  transition: background-color 300ms ease-out;
  background-color: #4D4D4D;
  content: "";
  height: 20px;
  left: -40px;
  position: absolute;
  top: 40px;
  width: 100px;
}
@media (max-width: 680px) {
  body.light .cross:after {
    height: 16px;
    width: 80px;
    left: -32px;
    top: 30px;
  }
}
body.light .circle,
body.light .bigCircle {
  transition: background-color 300ms ease-out;
  position: absolute;
  display: inline-block;
  border-radius: 50%;
}
body.light .circle {
  background-color: #fff2c9;
}
@media (max-width: 680px) {
  body.light .circle {
    top: calc(36px / -2);
    left: calc(calc(36px / -2) - 6px / 2);
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 681px) {
  body.light .circle {
    top: calc(44px / -2);
    left: calc(calc(44px / -2) - 6px / 2);
    width: 44px;
    height: 44px;
  }
}
body.light .bigCircle {
  background-color: #ffe493;
}
@media (max-width: 680px) {
  body.light .bigCircle {
    top: calc(44px / -2);
    left: calc(calc(44px / -2) - 6px / 2);
    width: 44px;
    height: 44px;
  }
}
@media (min-width: 681px) {
  body.light .bigCircle {
    top: calc(64px / -2);
    left: calc(calc(64px / -2) - 6px / 2);
    width: 64px;
    height: 64px;
  }
}
body.light .fadeInAnimation {
  animation: fadeInAnimation ease-out 1.5s;
  animation-iteration-count: 1;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
body.light *,
body.light *:before,
body.light *:after {
  box-sizing: inherit;
  font-family: inherit;
}
body.light #root {
  box-sizing: border-box;
  min-height: 100vh;
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  color: #000;
  margin: 0;
  background-color: #FFFFFF;
  transition: background-color 300ms;
  -ms-transition: background-color 300ms;
  -webkit-transition: background-color 300ms;
  white-space: pre-line;
}
body.light main {
  margin: 0 auto;
  padding-bottom: 8px;
  max-width: 560px;
  text-align: center;
  color: #000;
}
body.light main.error {
  margin-top: 24px;
  line-height: 24px;
}
body.light section {
  height: 540px;
}
body.light button {
  transition: all 300ms ease;
}
body.light .info button {
  position: relative;
  height: 32px;
  width: 140px;
  cursor: pointer;
  background-color: #FFAC26;
  border: none;
  border-radius: 4px;
  transition: 0.5s;
  color: #FFFFFF;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
body.light .info button:hover {
  background-color: #ffd38c;
}
body.light label *,
body.light label *:before,
body.light label *:after,
body.light .label *,
body.light .label *:before,
body.light .label *:after {
  box-sizing: initial;
}
body.light label,
body.light .label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  margin: 0 8px;
  padding: 0 12px;
  border-bottom: 2px solid #FFAC26;
  transition: border-bottom-color 0.3s ease;
}
@media (max-width: 700px) {
  body.light label,
  body.light .label {
    justify-content: space-between;
    width: 90%;
    max-width: 340px;
  }
}
body.light label.disabled,
body.light .label.disabled {
  border-bottom-color: #CCC;
}
body.light label span,
body.light .label span {
  margin-right: 16px;
}
body.light label .switch,
body.light .label .switch {
  border: 2px solid #FFAC26;
  background-color: #FFAC26;
  position: relative;
  width: 56px;
  height: 28px;
  overflow: hidden;
  border-radius: 100px;
}
body.light label .switch.disabled,
body.light .label .switch.disabled {
  border: 2px solid #CCC;
  background-color: #CCC;
}
body.light label .switch.disabled .knobs:before,
body.light .label .switch.disabled .knobs:before {
  color: #FFFFFF;
}
body.light label .switch .layer,
body.light .label .switch .layer,
body.light label .switch .knobs,
body.light .label .switch .knobs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: 0.3s ease all;
}
body.light label .switch .layer,
body.light .label .switch .layer {
  width: 100%;
  background-color: #FFAC26;
  z-index: 1;
}
body.light label .switch .knobs,
body.light .label .switch .knobs {
  z-index: 2;
}
body.light label .switch .knobs:before,
body.light .label .switch .knobs:before {
  content: "ANO";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 10px;
  color: #4D4D4D;
  font-size: 8px;
  font-weight: bold;
  text-align: center;
  line-height: 10px;
  padding: 5px 1px;
  background-color: #FFFFFF;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}
body.light label .switch .checkbox,
body.light .label .switch .checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}
body.light label .switch .checkbox:checked + .knobs:before,
body.light .label .switch .checkbox:checked + .knobs:before {
  content: "NE";
  left: 30px;
  background-color: #FFAC26;
  color: #FFFFFF;
}
body.light label .switch .checkbox:checked ~ .layer,
body.light .label .switch .checkbox:checked ~ .layer {
  background-color: #FFFFFF;
}
body.light label .switch .checkbox:disabled + .knobs:before,
body.light .label .switch .checkbox:disabled + .knobs:before {
  background-color: #4D4D4D;
}
body.light label .switch .checkbox:disabled ~ .layer,
body.light .label .switch .checkbox:disabled ~ .layer {
  background-color: #CCC;
}
body.light header h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 220px;
  margin: 0;
  text-align: center;
  font-size: 48px;
  font-weight: normal;
  letter-spacing: 24px;
  text-indent: 24px;
  text-transform: uppercase;
}
@media (max-width: 500px) {
  body.light header h1 {
    height: 140px;
  }
}
body.light header h1 h2,
body.light header h1 span {
  padding: 0 45px;
  position: relative;
  z-index: 1;
  background-color: #FFFFFF;
  transition: background-color 300ms;
  -ms-transition: background-color 300ms;
  -webkit-transition: background-color 300ms;
}
@media (max-width: 500px) {
  body.light header h1 h2,
  body.light header h1 span {
    padding: 0 8px;
  }
}
body.light header h1:before {
  content: "";
  display: block;
  border-top: solid 12px #FFAC26;
  width: 100%;
  position: absolute;
  top: calc(calc((220px / 2) - calc(12px / 2)));
  z-index: 0;
}
@media (max-width: 500px) {
  body.light header h1:before {
    top: calc(calc((140px / 2) - calc(12px / 2)));
  }
}
@media (max-width: 680px) {
  body.light header h1 {
    letter-spacing: 20px;
    text-indent: 20px;
  }
  body.light header h1 span {
    padding: 0 8px;
  }
}
@media (max-width: 500px) {
  body.light header h1 {
    letter-spacing: 12px;
    text-indent: 12px;
    font-size: 32px;
  }
}
@media (max-width: 370px) {
  body.light header h1 {
    letter-spacing: 8px;
    text-indent: 8px;
    padding: 0;
  }
}
body.light header a.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  text-align: center;
  color: inherit;
  font-size: 15px;
  text-decoration: none;
}
body.light header a.logo svg {
  width: 14px;
  height: 14px;
  margin-left: 3px;
}
@media (max-width: 500px) {
  body.light header a.logo {
    font-size: 12px;
  }
  body.light header a.logo svg {
    width: 11px;
    height: 11px;
  }
}
body.light nav {
  border-bottom: 4px solid #FFAC26;
}
body.light nav div.links {
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  max-width: 1280px;
  padding: 0 8px;
}
@media (max-width: 500px) {
  body.light nav div.links {
    flex-direction: column;
  }
}
@media (min-width: 501px) and (max-width: 700px) {
  body.light nav div.links {
    max-width: 680px;
  }
}
@media (min-width: 701px) and (max-width: 1150px) {
  body.light nav div.links {
    flex-wrap: nowrap;
  }
}
@media (min-width: 1151px) {
  body.light nav div.links {
    flex-wrap: nowrap;
  }
}
body.light nav div.links a {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  color: #000;
  padding: 8px 16px;
  text-align: center;
  flex-basis: 216px;
  margin: 0 4px;
  border-left: 3px solid #FFFFFF;
  border-right: 3px solid #FFFFFF;
  transition: 300ms all;
}
@media (max-width: 500px) {
  body.light nav div.links a {
    flex-basis: initial;
    letter-spacing: 1px;
    min-width: 80%;
    transition: font-weight 0s, border-left-color 300ms, border-right-color 300ms;
  }
}
@media (min-width: 501px) and (max-width: 700px) {
  body.light nav div.links a {
    margin: 4px;
  }
  body.light nav div.links a:first-child {
    flex-basis: 100%;
    margin: 4px 30%;
  }
}
@media (min-width: 701px) and (max-width: 1150px) {
  body.light nav div.links a {
    flex-basis: 180px;
  }
}
body.light nav div.links a:hover,
body.light nav div.links a.active {
  border-left-color: #FFAC26;
  border-right-color: #FFAC26;
  margin-bottom: 6px;
}
@media (max-width: 500px) {
  body.light nav div.links a:hover,
  body.light nav div.links a.active {
    font-weight: 700;
    margin-bottom: 0;
  }
}
body.light .title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 220px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  margin-top: 16px;
  height: 100px !important;
}
@media (max-width: 500px) {
  body.light .title {
    height: 140px;
  }
}
body.light .title h2,
body.light .title span {
  padding: 0 45px;
  position: relative;
  z-index: 1;
  background-color: #FFFFFF;
  transition: background-color 300ms;
  -ms-transition: background-color 300ms;
  -webkit-transition: background-color 300ms;
}
@media (max-width: 500px) {
  body.light .title h2,
  body.light .title span {
    padding: 0 8px;
  }
}
body.light .title:before {
  content: "";
  display: block;
  border-top: solid 12px #FFAC26;
  width: 100%;
  position: absolute;
  top: calc(calc((220px / 2) - calc(12px / 2)));
  z-index: 0;
}
@media (max-width: 500px) {
  body.light .title:before {
    top: calc(calc((140px / 2) - calc(12px / 2)));
  }
}
body.light .title .adjective {
  font-weight: bold;
}
body.light .title h2 {
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: 500;
  padding: 0 34px;
}
@media (max-width: 500px) {
  body.light .title h2 {
    letter-spacing: 1px;
    padding: 0 12px;
  }
}
body.light .title:before {
  margin: auto;
  border-top: solid 4px #B2B2B2;
  top: calc(calc((100px / 2) - calc(4px / 4)));
}
@media (max-width: 680px) {
  body.light .title:before {
    width: 90%;
    left: calc((100% - 90%) / 2);
  }
}
@media (min-width: 681px) {
  body.light .title:before {
    width: 80%;
    left: calc((100% - 80%) / 2);
  }
}
body.light .info {
  animation: fadeInAnimation ease-out 1.5s;
  animation-iteration-count: 1;
  position: relative;
  z-index: 1;
  transition: color 300ms ease-out;
  margin-bottom: 18px;
  line-height: 32px;
  font-style: italic;
  font-size: 15px;
  letter-spacing: 1px;
}
body.light .info span {
  display: flex;
  justify-content: center;
  align-items: center;
}
body.light .info span + span {
  line-height: initial;
}
body.light .info span + span svg {
  margin: 0 4px;
}
body.light .info svg {
  transition: color 300ms ease-out;
  margin-right: 6px;
  height: 22px;
  width: 22px;
}
body.light .tools {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  position: relative;
  z-index: 1;
}
@media (max-width: 700px) {
  body.light .tools {
    margin-top: 16px;
    flex-direction: column;
    align-items: center;
  }
}
body.light .advanced {
  margin: auto;
  text-align: center;
}
body.light .advanced .content {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms ease-out;
}
body.light .advanced .content label,
body.light .advanced .content .label {
  width: 320px;
  max-width: 90%;
  justify-content: space-between;
  text-align: left;
}
body.light .advanced .content label.select,
body.light .advanced .content .label.select {
  height: 84px;
}
body.light .advanced .content label.select select,
body.light .advanced .content .label.select select {
  cursor: pointer;
  color: #000;
  background-color: #FFFFFF;
  background-image: url("../images/select-arrow-down.png");
  background-position: right 6px center;
  background-repeat: no-repeat;
  background-size: auto 50%;
  border-radius: 2px;
  border: 1px solid #FFAC26;
  padding: 10px 22px 10px 6px;
  transition: background-color 300ms;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
body.light .advanced .content label.select select::-ms-expand,
body.light .advanced .content .label.select select::-ms-expand {
  display: none;
}
body.light .advanced .content label.select select:hover,
body.light .advanced .content .label.select select:hover,
body.light .advanced .content label.select select:focus,
body.light .advanced .content .label.select select:focus {
  background-color: #F9F9F9;
}
body.light .advanced .content label.select select:focus,
body.light .advanced .content .label.select select:focus {
  background-image: url("../images/select-arrow-up.png");
}
body.light .advanced button {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  height: 40px;
  margin-top: 8px;
  padding: 4px 6px;
  background-color: transparent;
  border-style: none;
  border-bottom: 2px #B2B2B2 solid;
  transition: 300ms padding-bottom;
}
body.light .advanced button:hover {
  padding-bottom: 10px;
}
body.light .advanced button svg {
  position: relative;
  top: 2px;
}
body.light .mysteries .prayer:first-child .left,
body.light main > .prayer:first-child .left {
  align-self: flex-start;
}
body.light main > .prayer:first-child,
body.light .mysteries .prayer:first-child {
  padding-top: 0;
}
body.light .prayer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-left: 6px solid #B2B2B2;
  padding-top: 28px;
}
@media (max-width: 680px) {
  body.light .prayer {
    margin-left: 42px;
    padding-top: 24px;
  }
}
body.light .prayer .left,
body.light .prayer .right {
  position: relative;
}
body.light .prayer .right {
  width: 88%;
  padding-right: 12%;
}
@media (max-width: 680px) {
  body.light .prayer .right {
    width: 92%;
    padding-right: 8px;
    padding-left: 20px;
  }
}
body.light .prayer .right p {
  border-radius: 8px;
  cursor: default;
  margin: 0;
  padding: 8px;
  background-color: #ffd38c;
  border: 2px solid #ffd38c;
  transition: background-color 300ms ease-out, border-color 300ms ease-out, color 300ms ease-out;
}
body.light .prayer .right p.signOfTheCrossFirst,
body.light .prayer .right p.signOfTheCrossSecond {
  background-color: #ffc76b;
  border-color: #ffc76b;
}
body.light .prayer .right p.ourFather,
body.light .prayer .right p.hailHolyQueen {
  background-color: #ffe493;
  border-color: #ffe493;
}
body.light .prayer .right p.hailMary {
  background-color: #fff2c9;
  border-color: #fff2c9;
}
body.light .prayer .right p.gloryBe,
body.light .prayer .right p.apostlesCreed {
  background-color: #ffd299;
  border-color: #ffd299;
}
@media (min-width: 501px) and (min-height: 500px) {
  body.light .prayer .right p:hover {
    background-color: #FFFFFF !important;
    color: #CCC !important;
  }
}
body.light .prayer .right p.short {
  font-style: italic;
}
body.light .prayer.grey .right p {
  background-color: #FFFFFF !important;
  border-color: #B2B2B2 !important;
  color: #CCC !important;
}
body.light .prayer.grey .left span,
body.light .prayer.grey .left span.cross,
body.light .prayer.grey .left span.cross:after {
  background-color: #CCC;
}
body.light .mysteries p {
  font-style: normal !important;
}
body.light .mysteries .prayer:first-child p {
  border-color: #ffc76b;
  background-color: #ffc76b;
}
body.light .mysteries .prayer:nth-child(2) p {
  border-color: #ffd299;
  background-color: #ffd299;
}
body.light .mysteries .prayer:nth-child(3) p {
  border-color: #ffe493;
  background-color: #ffe493;
}
body.light .mysteries .prayer:nth-child(4) p {
  border-color: #ffd299;
  background-color: #ffd299;
}
body.light .mysteries .prayer:last-child p {
  border-color: #ffc76b;
  background-color: #ffc76b;
}
body.light .greyBox {
  background-color: #FFFFFF !important;
  border-color: #B2B2B2 !important;
  color: #CCC !important;
}
body.light .decade.indexes {
  counter-reset: indexes;
}
body.light .decade.indexes .prayer p.hailMary {
  position: relative;
  counter-increment: indexes;
}
body.light .decade.indexes .prayer p.hailMary::after {
  content: counter(indexes) ".";
  position: absolute;
  top: 2px;
  right: 6px;
  font-style: normal;
  font-size: 14px;
  color: #4D4D4D;
}
body.light .decade.indexes .prayer.grey p.hailMary::after {
  color: #CCC;
}
body.light footer {
  text-align: center;
  font-size: 15px;
}
body.light footer p {
  margin: 24px 0 12px 0;
}
body.light footer a {
  color: darkblue;
}
body.dark {
  /* <body> */
  margin: 0;
  background-color: #121212;
  transition: background-color 300ms;
  -ms-transition: background-color 300ms;
  -webkit-transition: background-color 300ms;
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq3p6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v26/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf) format('truetype');
}
body.dark .title-stripe {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 220px;
  margin: 0;
  text-align: center;
}
@media (max-width: 500px) {
  body.dark .title-stripe {
    height: 140px;
  }
}
body.dark .title-stripe h2,
body.dark .title-stripe span {
  padding: 0 45px;
  position: relative;
  z-index: 1;
  background-color: #121212;
  transition: background-color 300ms;
  -ms-transition: background-color 300ms;
  -webkit-transition: background-color 300ms;
}
@media (max-width: 500px) {
  body.dark .title-stripe h2,
  body.dark .title-stripe span {
    padding: 0 8px;
  }
}
body.dark .title-stripe:before {
  content: "";
  display: block;
  border-top: solid 12px #dea447;
  width: 100%;
  position: absolute;
  top: calc(calc((220px / 2) - calc(12px / 2)));
  z-index: 0;
}
@media (max-width: 500px) {
  body.dark .title-stripe:before {
    top: calc(calc((140px / 2) - calc(12px / 2)));
  }
}
body.dark .cross {
  position: absolute;
  display: inline-block;
  background-color: #fff2c9;
  transition: background-color 300ms ease-out;
}
@media (max-width: 680px) {
  body.dark .cross {
    height: 115px;
    width: 16px;
    left: calc(calc(16px / -2) - 6px / 2);
  }
}
@media (min-width: 681px) {
  body.dark .cross {
    height: 150px;
    width: 20px;
    left: calc(calc(20px / -2) - 6px / 2);
  }
}
body.dark .cross:after {
  transition: background-color 300ms ease-out;
  background-color: #fff2c9;
  content: "";
  height: 20px;
  left: -40px;
  position: absolute;
  top: 40px;
  width: 100px;
}
@media (max-width: 680px) {
  body.dark .cross:after {
    height: 16px;
    width: 80px;
    left: -32px;
    top: 30px;
  }
}
body.dark .circle,
body.dark .bigCircle {
  transition: background-color 300ms ease-out;
  position: absolute;
  display: inline-block;
  border-radius: 50%;
}
body.dark .circle {
  background-color: #d8c58a;
}
@media (max-width: 680px) {
  body.dark .circle {
    top: calc(36px / -2);
    left: calc(calc(36px / -2) - 6px / 2);
    width: 36px;
    height: 36px;
  }
}
@media (min-width: 681px) {
  body.dark .circle {
    top: calc(44px / -2);
    left: calc(calc(44px / -2) - 6px / 2);
    width: 44px;
    height: 44px;
  }
}
body.dark .bigCircle {
  background-color: #cbb061;
}
@media (max-width: 680px) {
  body.dark .bigCircle {
    top: calc(44px / -2);
    left: calc(calc(44px / -2) - 6px / 2);
    width: 44px;
    height: 44px;
  }
}
@media (min-width: 681px) {
  body.dark .bigCircle {
    top: calc(64px / -2);
    left: calc(calc(64px / -2) - 6px / 2);
    width: 64px;
    height: 64px;
  }
}
body.dark .fadeInAnimation {
  animation: fadeInAnimation ease-out 1.5s;
  animation-iteration-count: 1;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
body.dark *,
body.dark *:before,
body.dark *:after {
  box-sizing: inherit;
  font-family: inherit;
}
body.dark #root {
  box-sizing: border-box;
  min-height: 100vh;
  font-family: 'Montserrat', Arial, sans-serif;
  font-size: 16px;
  font-style: normal;
  color: rgba(255, 255, 255, 0.87);
  margin: 0;
  background-color: #121212;
  transition: background-color 300ms;
  -ms-transition: background-color 300ms;
  -webkit-transition: background-color 300ms;
  white-space: pre-line;
}
body.dark main {
  margin: 0 auto;
  padding-bottom: 8px;
  max-width: 560px;
  text-align: center;
  color: #000;
}
body.dark main.error {
  margin-top: 24px;
  line-height: 24px;
}
body.dark section {
  height: 540px;
}
body.dark button {
  transition: all 300ms ease;
}
body.dark .info button {
  position: relative;
  height: 32px;
  width: 140px;
  cursor: pointer;
  background-color: #dea447;
  border: none;
  border-radius: 4px;
  transition: 0.5s;
  color: #121212;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
body.dark .info button:hover {
  background-color: #c99f5c;
}
body.dark label *,
body.dark label *:before,
body.dark label *:after,
body.dark .label *,
body.dark .label *:before,
body.dark .label *:after {
  box-sizing: initial;
}
body.dark label,
body.dark .label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  margin: 0 8px;
  padding: 0 12px;
  border-bottom: 2px solid #dea447;
  transition: border-bottom-color 0.3s ease;
}
@media (max-width: 700px) {
  body.dark label,
  body.dark .label {
    justify-content: space-between;
    width: 90%;
    max-width: 340px;
  }
}
body.dark label.disabled,
body.dark .label.disabled {
  border-bottom-color: #4D4D4D;
}
body.dark label span,
body.dark .label span {
  margin-right: 16px;
}
body.dark label .switch,
body.dark .label .switch {
  border: 2px solid #dea447;
  background-color: #dea447;
  position: relative;
  width: 56px;
  height: 28px;
  overflow: hidden;
  border-radius: 100px;
}
body.dark label .switch.disabled,
body.dark .label .switch.disabled {
  border: 2px solid #4D4D4D;
  background-color: #4D4D4D;
}
body.dark label .switch.disabled .knobs:before,
body.dark .label .switch.disabled .knobs:before {
  color: #121212;
}
body.dark label .switch .layer,
body.dark .label .switch .layer,
body.dark label .switch .knobs,
body.dark .label .switch .knobs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: 0.3s ease all;
}
body.dark label .switch .layer,
body.dark .label .switch .layer {
  width: 100%;
  background-color: #dea447;
  z-index: 1;
}
body.dark label .switch .knobs,
body.dark .label .switch .knobs {
  z-index: 2;
}
body.dark label .switch .knobs:before,
body.dark .label .switch .knobs:before {
  content: "ANO";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 10px;
  color: #CCC;
  font-size: 8px;
  font-weight: bold;
  text-align: center;
  line-height: 10px;
  padding: 5px 1px;
  background-color: #121212;
  border-radius: 50%;
  transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
}
body.dark label .switch .checkbox,
body.dark .label .switch .checkbox {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 3;
}
body.dark label .switch .checkbox:checked + .knobs:before,
body.dark .label .switch .checkbox:checked + .knobs:before {
  content: "NE";
  left: 30px;
  background-color: #dea447;
  color: #121212;
}
body.dark label .switch .checkbox:checked ~ .layer,
body.dark .label .switch .checkbox:checked ~ .layer {
  background-color: #121212;
}
body.dark label .switch .checkbox:disabled + .knobs:before,
body.dark .label .switch .checkbox:disabled + .knobs:before {
  background-color: #CCC;
}
body.dark label .switch .checkbox:disabled ~ .layer,
body.dark .label .switch .checkbox:disabled ~ .layer {
  background-color: #4D4D4D;
}
body.dark header h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 220px;
  margin: 0;
  text-align: center;
  font-size: 48px;
  font-weight: normal;
  letter-spacing: 24px;
  text-indent: 24px;
  text-transform: uppercase;
}
@media (max-width: 500px) {
  body.dark header h1 {
    height: 140px;
  }
}
body.dark header h1 h2,
body.dark header h1 span {
  padding: 0 45px;
  position: relative;
  z-index: 1;
  background-color: #121212;
  transition: background-color 300ms;
  -ms-transition: background-color 300ms;
  -webkit-transition: background-color 300ms;
}
@media (max-width: 500px) {
  body.dark header h1 h2,
  body.dark header h1 span {
    padding: 0 8px;
  }
}
body.dark header h1:before {
  content: "";
  display: block;
  border-top: solid 12px #dea447;
  width: 100%;
  position: absolute;
  top: calc(calc((220px / 2) - calc(12px / 2)));
  z-index: 0;
}
@media (max-width: 500px) {
  body.dark header h1:before {
    top: calc(calc((140px / 2) - calc(12px / 2)));
  }
}
@media (max-width: 680px) {
  body.dark header h1 {
    letter-spacing: 20px;
    text-indent: 20px;
  }
  body.dark header h1 span {
    padding: 0 8px;
  }
}
@media (max-width: 500px) {
  body.dark header h1 {
    letter-spacing: 12px;
    text-indent: 12px;
    font-size: 32px;
  }
}
@media (max-width: 370px) {
  body.dark header h1 {
    letter-spacing: 8px;
    text-indent: 8px;
    padding: 0;
  }
}
body.dark header a.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px;
  text-align: center;
  color: inherit;
  font-size: 15px;
  text-decoration: none;
}
body.dark header a.logo svg {
  width: 14px;
  height: 14px;
  margin-left: 3px;
}
@media (max-width: 500px) {
  body.dark header a.logo {
    font-size: 12px;
  }
  body.dark header a.logo svg {
    width: 11px;
    height: 11px;
  }
}
body.dark nav {
  border-bottom: 4px solid #dea447;
}
body.dark nav div.links {
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  max-width: 1280px;
  padding: 0 8px;
}
@media (max-width: 500px) {
  body.dark nav div.links {
    flex-direction: column;
  }
}
@media (min-width: 501px) and (max-width: 700px) {
  body.dark nav div.links {
    max-width: 680px;
  }
}
@media (min-width: 701px) and (max-width: 1150px) {
  body.dark nav div.links {
    flex-wrap: nowrap;
  }
}
@media (min-width: 1151px) {
  body.dark nav div.links {
    flex-wrap: nowrap;
  }
}
body.dark nav div.links a {
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.87);
  padding: 8px 16px;
  text-align: center;
  flex-basis: 216px;
  margin: 0 4px;
  border-left: 3px solid #121212;
  border-right: 3px solid #121212;
  transition: 300ms all;
}
@media (max-width: 500px) {
  body.dark nav div.links a {
    flex-basis: initial;
    letter-spacing: 1px;
    min-width: 80%;
    transition: font-weight 0s, border-left-color 300ms, border-right-color 300ms;
  }
}
@media (min-width: 501px) and (max-width: 700px) {
  body.dark nav div.links a {
    margin: 4px;
  }
  body.dark nav div.links a:first-child {
    flex-basis: 100%;
    margin: 4px 30%;
  }
}
@media (min-width: 701px) and (max-width: 1150px) {
  body.dark nav div.links a {
    flex-basis: 180px;
  }
}
body.dark nav div.links a:hover,
body.dark nav div.links a.active {
  border-left-color: #dea447;
  border-right-color: #dea447;
  margin-bottom: 6px;
}
@media (max-width: 500px) {
  body.dark nav div.links a:hover,
  body.dark nav div.links a.active {
    font-weight: 700;
    margin-bottom: 0;
  }
}
body.dark .title {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 220px;
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  margin-top: 16px;
  height: 100px !important;
}
@media (max-width: 500px) {
  body.dark .title {
    height: 140px;
  }
}
body.dark .title h2,
body.dark .title span {
  padding: 0 45px;
  position: relative;
  z-index: 1;
  background-color: #121212;
  transition: background-color 300ms;
  -ms-transition: background-color 300ms;
  -webkit-transition: background-color 300ms;
}
@media (max-width: 500px) {
  body.dark .title h2,
  body.dark .title span {
    padding: 0 8px;
  }
}
body.dark .title:before {
  content: "";
  display: block;
  border-top: solid 12px #dea447;
  width: 100%;
  position: absolute;
  top: calc(calc((220px / 2) - calc(12px / 2)));
  z-index: 0;
}
@media (max-width: 500px) {
  body.dark .title:before {
    top: calc(calc((140px / 2) - calc(12px / 2)));
  }
}
body.dark .title .adjective {
  font-weight: bold;
}
body.dark .title h2 {
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: 500;
  padding: 0 34px;
}
@media (max-width: 500px) {
  body.dark .title h2 {
    letter-spacing: 1px;
    padding: 0 12px;
  }
}
body.dark .title:before {
  margin: auto;
  border-top: solid 4px #7f7f7f;
  top: calc(calc((100px / 2) - calc(4px / 4)));
}
@media (max-width: 680px) {
  body.dark .title:before {
    width: 90%;
    left: calc((100% - 90%) / 2);
  }
}
@media (min-width: 681px) {
  body.dark .title:before {
    width: 80%;
    left: calc((100% - 80%) / 2);
  }
}
body.dark .info {
  animation: fadeInAnimation ease-out 1.5s;
  animation-iteration-count: 1;
  position: relative;
  z-index: 1;
  transition: color 300ms ease-out;
  margin-bottom: 18px;
  line-height: 32px;
  font-style: italic;
  font-size: 15px;
  letter-spacing: 1px;
}
body.dark .info span {
  display: flex;
  justify-content: center;
  align-items: center;
}
body.dark .info span + span {
  line-height: initial;
}
body.dark .info span + span svg {
  margin: 0 4px;
}
body.dark .info svg {
  transition: color 300ms ease-out;
  margin-right: 6px;
  height: 22px;
  width: 22px;
}
body.dark .tools {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  position: relative;
  z-index: 1;
}
@media (max-width: 700px) {
  body.dark .tools {
    margin-top: 16px;
    flex-direction: column;
    align-items: center;
  }
}
body.dark .advanced {
  margin: auto;
  text-align: center;
}
body.dark .advanced .content {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-height: 0;
  overflow: hidden;
  transition: max-height 300ms ease-out;
}
body.dark .advanced .content label,
body.dark .advanced .content .label {
  width: 320px;
  max-width: 90%;
  justify-content: space-between;
  text-align: left;
}
body.dark .advanced .content label.select,
body.dark .advanced .content .label.select {
  height: 84px;
}
body.dark .advanced .content label.select select,
body.dark .advanced .content .label.select select {
  cursor: pointer;
  color: rgba(255, 255, 255, 0.87);
  background-color: #121212;
  background-image: url("../images/select-arrow-down.png");
  background-position: right 6px center;
  background-repeat: no-repeat;
  background-size: auto 50%;
  border-radius: 2px;
  border: 1px solid #dea447;
  padding: 10px 22px 10px 6px;
  transition: background-color 300ms;
  outline: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}
body.dark .advanced .content label.select select::-ms-expand,
body.dark .advanced .content .label.select select::-ms-expand {
  display: none;
}
body.dark .advanced .content label.select select:hover,
body.dark .advanced .content .label.select select:hover,
body.dark .advanced .content label.select select:focus,
body.dark .advanced .content .label.select select:focus {
  background-color: #2d2d2d;
}
body.dark .advanced .content label.select select:focus,
body.dark .advanced .content .label.select select:focus {
  background-image: url("../images/select-arrow-up.png");
}
body.dark .advanced button {
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.87);
  height: 40px;
  margin-top: 8px;
  padding: 4px 6px;
  background-color: transparent;
  border-style: none;
  border-bottom: 2px #7f7f7f solid;
  transition: 300ms padding-bottom;
}
body.dark .advanced button:hover {
  padding-bottom: 10px;
}
body.dark .advanced button svg {
  position: relative;
  top: 2px;
}
body.dark .mysteries .prayer:first-child .left,
body.dark main > .prayer:first-child .left {
  align-self: flex-start;
}
body.dark main > .prayer:first-child,
body.dark .mysteries .prayer:first-child {
  padding-top: 0;
}
body.dark .prayer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  border-left: 6px solid #7f7f7f;
  padding-top: 28px;
}
@media (max-width: 680px) {
  body.dark .prayer {
    margin-left: 42px;
    padding-top: 24px;
  }
}
body.dark .prayer .left,
body.dark .prayer .right {
  position: relative;
}
body.dark .prayer .right {
  width: 88%;
  padding-right: 12%;
}
@media (max-width: 680px) {
  body.dark .prayer .right {
    width: 92%;
    padding-right: 8px;
    padding-left: 20px;
  }
}
body.dark .prayer .right p {
  border-radius: 8px;
  cursor: default;
  margin: 0;
  padding: 8px;
  background-color: #c99f5c;
  border: 2px solid #c99f5c;
  transition: background-color 300ms ease-out, border-color 300ms ease-out, color 300ms ease-out;
}
body.dark .prayer .right p.signOfTheCrossFirst,
body.dark .prayer .right p.signOfTheCrossSecond {
  background-color: #c19143;
  border-color: #c19143;
}
body.dark .prayer .right p.ourFather,
body.dark .prayer .right p.hailHolyQueen {
  background-color: #cbb061;
  border-color: #cbb061;
}
body.dark .prayer .right p.hailMary {
  background-color: #d8c58a;
  border-color: #d8c58a;
}
body.dark .prayer .right p.gloryBe,
body.dark .prayer .right p.apostlesCreed {
  background-color: #cc9f66;
  border-color: #cc9f66;
}
@media (min-width: 501px) and (min-height: 500px) {
  body.dark .prayer .right p:hover {
    background-color: #121212 !important;
    color: #4D4D4D !important;
  }
}
body.dark .prayer .right p.short {
  font-style: italic;
}
body.dark .prayer.grey .right p {
  background-color: #121212 !important;
  border-color: #7f7f7f !important;
  color: #4D4D4D !important;
}
body.dark .prayer.grey .left span,
body.dark .prayer.grey .left span.cross,
body.dark .prayer.grey .left span.cross:after {
  background-color: #7f7f7f;
}
body.dark .mysteries p {
  font-style: normal !important;
}
body.dark .mysteries .prayer:first-child p {
  border-color: #c19143;
  background-color: #c19143;
}
body.dark .mysteries .prayer:nth-child(2) p {
  border-color: #cc9f66;
  background-color: #cc9f66;
}
body.dark .mysteries .prayer:nth-child(3) p {
  border-color: #cbb061;
  background-color: #cbb061;
}
body.dark .mysteries .prayer:nth-child(4) p {
  border-color: #cc9f66;
  background-color: #cc9f66;
}
body.dark .mysteries .prayer:last-child p {
  border-color: #c19143;
  background-color: #c19143;
}
body.dark .greyBox {
  background-color: #121212 !important;
  border-color: #7f7f7f !important;
  color: #4D4D4D !important;
}
body.dark .decade.indexes {
  counter-reset: indexes;
}
body.dark .decade.indexes .prayer p.hailMary {
  position: relative;
  counter-increment: indexes;
}
body.dark .decade.indexes .prayer p.hailMary::after {
  content: counter(indexes) ".";
  position: absolute;
  top: 2px;
  right: 6px;
  font-style: normal;
  font-size: 14px;
  color: #1a1a1a;
}
body.dark .decade.indexes .prayer.grey p.hailMary::after {
  color: #4D4D4D;
}
body.dark footer {
  text-align: center;
  font-size: 15px;
}
body.dark footer p {
  margin: 24px 0 12px 0;
}
body.dark footer a {
  color: lightblue;
}
